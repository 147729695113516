<template>
  <div :class="'page'">
    <nav class='flex'>
      <ul v-if="collection_set_id">
        <li class="title active">
          <a :href="'/collection_sets/' + collection_set_id">
            <label>
              <div style='font-size: 40px;margin-right:8px;line-height:1'>&#8592;</div>
              <div>
                <img class="set-symbol" :src="collection_set_symbol_url" :alt="collection_set_code" :title="collection_set_code" />
              </div>
              <div class="ml-8">
                <img class="set-logo" :src="collection_set_logo_url" :alt="collection_set_name" :title="collection_set_name" />
              </div>
            </label>
          </a>
        </li>
      </ul>
    </nav>

    <div class='collectable-page flex'>
      <div class='flex w-50'>
        <div class='flex pa4 w-100'>
          <vue-load-image class='mx-auto flex'>
            <img :class="'image ' + (version == 'reverse' ? 'reverse' : '')" v-on:load="setupImageZoom" slot="image" :src="actualImageUrl" :data-original="actualImageUrl"/>
            <img slot="preloader" src="/images/image-loader.gif" />
            <div slot="error">Nie udało się załadować zdjęcia</div>
          </vue-load-image>
        </div>
      </div>
      <div class='flex w-50 flex-column'>
        <h1 class='center w-100 center'>
          {{ name }}
          <span class="version" v-if="version != 'regular'">{{ version }}</span>
        </h1>

        <div>
          <p v-html="description" />

          <p class="price" v-if="!old_price"><span>Cena: {{ price }}zł</span></p>
          <!-- <p class="price" v-if="!old_price"><span>Cena: {{ price }}zł</span> | Stan: {{ quantity_for_trade }}</span> -->

          <p class="price" v-if="old_price"><span style="text-decoration:line-through">Cena: {{ old_price }}zł</span></p>
          <!-- <p class="price" v-if="old_price"><span style="text-decoration:line-through">Cena: {{ old_price }}zł</span> | Stan: {{ quantity_for_trade }}</p> -->
          <p class="price discount" v-if="old_price">Promocyjna cena: {{ price }}zł</p>

          <div class="cart" v-if="quantity_for_trade > 0">
            <input class="quantity-to-cart" type="number" min="1" v-model="quantity_to_cart" v-bind:max="quantity_for_trade" />
            <button class="button add-to-cart" v-on:click="addToCart" title="Dodaj do koszyka">
              <img class="cart-icon" src="/images/shopping_trolley_plus.svg" />
            </button>
          </div>
          <div class="cart" style="color:#f77373" v-if="quantity_for_trade == 0">
            Brak w magazynie
          </div>
        </div>
      </div>
    </div>
  </div>
<!--     <input type="radio" id="version_all" value="all" v-model="selectedVersion" />
    <input type="radio" id="version_regular" value="regular" v-model="selectedVersion" />
    <input type="radio" id="version_reverse" value="reverse" v-model="selectedVersion" />
 -->    <!-- <h2 v-if='carousel' class='center'>Ostatnio dodane</h2> -->

<!--     <nav v-if="loaded">
      <ul v-if="collection_set_id && (paginationEnabled || !carouselEnabled)">
        <li class="title active">
          <a :href="'/collection_sets/' + collection_set_id">
            <label :style="carouselEnabled ? 'left:20px' : ''">
              <div>
                <img class="set-symbol" :src="symbol_url" :alt="collection_set_code" :title="collection_set_code" />
              </div>
              <div class="ml-8">
                <img class="set-logo" :src="logo_url" :alt="collection_set_name" :title="collection_set_name" />
              </div>
            </label>
          </a>
        </li>
      </ul>
      <ul v-if="hasVersions && !carouselEnabled">
        <li :class="{ active: selectedVersion == 'all'}"><label for="version_all">Wszystkie ({{ versionSize('all') }})</label></li>
        <li :class="{ active: selectedVersion == 'regular'}"><label for="version_regular">Zwyczajne ({{ versionSize('regular') }})</label></li>
        <li :class="{ active: selectedVersion == 'reverse'}"><label for="version_reverse">Reversy ({{ versionSize('reverse') }})</label></li>
      </ul>
    </nav>
 -->
    <!-- <img v-if="!loaded" :style="carouselEnabled ? 'width: auto;height: 471px' : ''" class="loader" src="/images/pokeball.gif" alt="Trwa ładowanie, proszę czekać..." title="Trwa ładowanie, proszę czekać..."/> -->
</template>
        <!-- :per-page-custom='[[532,1],[759,2],[986,3],[1213,4],[1440,5],[1667,6],[1894,7],[2121,8],[2348,9]]' -->

<script>
import VueLoadImage from 'vue-load-image'

export default {
  props: [
    'id'
  ],
  data() {
    return {
      name: '',
      description: '',
      image_url: '',
      original_image_url: '',
      zoom_set: false,
      quantity_for_trade: 0,
      price: null,
      old_price: null,
      version: '',
      quantity_to_cart: 1,
      collection_set_id: null,
      collection_set_name: null,
      collection_set_code: null,
      collection_set_symbol_url: null,
      collection_set_logo_url: null
    }
  },
  created() {
    if(this.id) {
      this.$http.get('/api/collectable_versions/' + this.id).then(function(response) {
        const data = response.body
        this.name = data.name
        this.description = data.description
        this.version = data.version
        this.image_url = data.image_url
        this.original_image_url = data.original_image_url
        this.price = data.price
        this.old_price = data.old_price
        this.quantity_for_trade = data.quantity_for_trade
        this.collection_set_id = data.collection_set_id
        this.collection_set_name = data.collection_set_name
        this.collection_set_code = data.collection_set_code
        this.collection_set_symbol_url = data.collection_set_symbol_url
        this.collection_set_logo_url = data.collection_set_logo_url
      });
    }
  },
  computed: {
    actualImageUrl: function() {
      return (this.version == 'reverse' || !this.original_image_url) ? this.image_url : this.original_image_url
    }
  },
  methods: {
    setupImageZoom: function() {
      return;
      if(!this.zoom_set) {
        new Zooming({
          bgOpacity: 0.5,
          onImageLoading: function() {
            $('#image-zooming-loading').removeClass('hidden')
          },
          onImageLoaded: function() {
            $('#image-zooming-loading').addClass('hidden')
          },
          onBeforeClose: function() {
            $('#image-zooming-loading').addClass('hidden')
          }
        }).listen('.collectable-page .image')
        this.zoom_set = true;
      }
    },
    addToCart: function() {
      if(this.quantity_to_cart == 0) { return }
      this.$http.post('/api/orders', {
        collectable_version_id: this.id,
        quantity: parseInt(this.quantity_to_cart)
      }).then(response => {
        this.$root.$emit('updateCart', response.body, this.name)
        var remainingQuantity = this.collectable.quantity_for_trade - response.body.item_quantity
        if(this.quantity_to_cart > remainingQuantity) {
          this.quantity_to_cart = remainingQuantity
        }
        if(response.body.error) { this.displayMessage(response.body, response.body.error) }
      });
    },
    displayMessage: function(cart, text) {
      $('.cart-alert').remove()
      var message = $("<div class='cart-alert' style='display:none'><a href='/orders/current'><p>"+text+"</p><p>Stan zamówienia: "+cart.price.toFixed(2)+"zł</p></a></div>")
      $('body').prepend(message)
      message.fadeIn(500, function() {
        setTimeout(function() { message.fadeOut(500); }, 5000)
      });
    }
  },
  components: {
    'vue-load-image': VueLoadImage
  }
}
</script>

<style scoped>
.collectable-page {
  font-size: 2em;
  border-width: 2px;
  border-color: #e8e8e8;
  border-style: dashed;
  padding: 8px 16px;
  color: white;
  background-color: #151515;
}
a {
  color: white;
}
.collectable-page h1 {
  color: white;
  text-align: center;
}
.collectable-page .image {
  max-width: 100%;
  object-fit: contain;
  object-position: top;
}

.version {
  font-weight: normal;
}
.reverse {
  border-radius: 15px;
}
/* Adding to cart */
.price {
  font-size: 18px;
  margin: 8px 0;
}
.discount {
  margin: 8px 0;
  background: linear-gradient(0deg, #a21414, #e01c1c);
  color: white;
  text-shadow: 1px 1px black;
  border-radius: 10px;
  border: 1px solid #823939;
  display: inline-block;
  padding: 4px 8px;
}

.price-cut {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(0deg, #a21414, #e01c1c);
  padding: 2px 5px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  text-shadow: 1px 1px black;
  border: 1px solid #823939;
  z-index: 1;
  font-size: 26px;
}

input {
  width: 40px;
  text-align: center;
  font-size: 20px;
  background-color: white;
  padding: 0;
  margin: 0;
}
input.quantity-to-cart {
  max-width: 150px;
  width: 100%;
  height: 23px;
  display: block;
  float: left;
  padding: 5px 0;
}
.add-to-cart {
  max-width: 150px;
  width: 100%;
  height: 37px;
  border-radius: 0;
  display: block;
  padding: 0;
  cursor: pointer;
  padding: 5px 0;
}
.add-to-cart img {
  height: 20px;
}
.cart {
  margin: 8px 0;
  padding-top: 5px;
  width: 100%;
}


/* Nav */
li {
  display: block;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav label {
  float: left;
  padding: 10px 25px;
  border: 1px solid #ddd;
  margin-top: 8px;
  border-bottom: 0;
  background: #696969;
  color: white;
}
nav label:hover {
  background: #807d7d;
}
nav label:active {
  background: #151515;
}
nav li:first-child label {
  border-radius: 10px 0 0 0;
}
nav li:last-child label {
  border-radius: 0 10px 0 0;
}
nav li:not(:last-child) label {
  border-right-width: 0;
}

section {
  clear: both;
  color: white;
  margin: 0 auto 25px;
}
nav li.active label {
  background: #151515;
  position: relative;
}
nav li.active label:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #151515;
  left: 0;
  bottom: -1px;
}

/* Set logo */
nav ul li.title label > div {
  height: 48px;
  line-height: 48px;
  float: left;
}
nav ul li.title label > div img {
  vertical-align: middle;
}
nav ul li.title label {
  margin-top: 0;
  padding: 0 8px;
  margin-right: 32px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
ul li.title label:after {
  bottom: -4px;
}

img.set-symbol {
  height: 35px;
}
img.set-logo {
  max-height: 35px;
}
</style>
