<template>
  <div class="collectable" v-if="item.quantity > 0">
    <div style='position: relative'>
      <!-- <div class='price-cut' v-if="discount">-{{discount}}%</div> -->
      <vue-load-image>
        <img class="card" slot="image" :src="item.image_url" />
        <img slot="preloader" src="/images/image-loader.gif" />
        <div slot="error">error message</div>
      </vue-load-image>
    </div>
    <p class="text-2-lines name" :title="displayNameWithVersion">
      {{ displayName }}
      <span class="version" v-if="item.version != 'regular'">{{ item.version }}</span>
    </p>
    <p class="price" v-if="!item.old_price"><span>Cena: {{ item.price }}zł</span></span>

    <p class="price" v-if="item.old_price"><span style="text-decoration:line-through">Cena: {{ item.old_price }}zł</span></span>
    <p class="price discount" v-if="item.old_price">Promocyjna cena: {{ item.price }}zł</span>
    <p class="price" v-if="!item.old_price">&nbsp;</span>
    <div class="cart">
      <label class="cart-quantity">🛒</label>
      <input :disabled="orderingMode" class="quantity-to-cart" type="number" min="0" v-model="item.quantity" v-bind:max="item.quantity_for_trade" v-on:change="updateItem" />
      <button v-if="!orderingMode" class="button delete" v-on:click="removeItem">Usuń</button>
    </div>
  </div>
</template>

<script>
import VueLoadImage from 'vue-load-image'
export default {
  props: {
    item: {},
    orderingMode: {}
  },
  data() {
    return {
      disabled: false
    }
  },
  methods: {
    updateItem: function() {
      this.disabled = true;
      this.$http.post('/api/orders', {
        order_item_id: this.item.id,
        quantity: this.item.quantity
      }).then(response => {
        this.disabled = false;
        this.item.quantity = response.body.item_quantity;
        this.$root.$emit('updateCart', response.body)
      });
    },
    removeItem: function() {
      this.item.quantity = 0;
      this.updateItem();
    }
  },
  computed: {
    discount: function() {
      if(!this.item.old_price) { return }
      return Math.round(100*(this.item.old_price-this.item.price)/this.item.old_price)
    },
    displayName: function() {
      var name = this.item.name
      if(this.item.collection_set_abbr) {
        name = name + ' ' + this.item.collection_set_abbr
      }
      return name
    },
    displayNameWithVersion: function() {
      var name = this.displayName
      if(this.item.version != 'regular') {
        name = name + ' ' + this.item.version
      }
      return name
    },
  },
  components: {
    'vue-load-image': VueLoadImage
  }
}
</script>

<style scoped>
div.collectable {
  text-align: center;
  display: inline-block;
  border-width: 2px;
  border-color: #e8e8e8;
  border-style: dashed;
  padding: 5px;
  color: white;
  background-color: #151515;
}
.delete {
  font-size: 13px;
  padding: 5px;
  width: 45px;
}
.name {
  font-weight: bolder;
  font-size: 13px;
  height: 38px;
}
.version {
  font-weight: normal;
}
.name, .details {
  width: 213px;
}
.vue-load-image {
  height: 300px;
  position: relative;
}
.vue-load-image img {
  border-radius: 8px;
  width: 213px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0%;
}
input {
  width: 40px;
  text-align: center;
  font-size: 15px;
  background-color: white;
  padding: 0;
  margin: 0;
}
input.quantity-to-cart {
  width: 40px;
  height: 23px;
  display: block;
  float: left;
}
label.cart-quantity {
  float: left;
  width: 30px;
  height: 27px;
  border-radius: 0;
  display: block;
  font-size: 23px;
}
.cart {
  margin: 0 auto;
  padding-top: 5px;
  width: 144px;
  line-height: 1;
}
p {
  margin: 0;
}
.icon {
  height: 20px;
}
.price {
  font-size: 12px;
}
.discount {
  background: #e01c1c;
  color: white;
  border-radius: 10px;
}

.discount {
  background: linear-gradient(0deg, #a21414, #e01c1c);
  color: white;
  text-shadow: 1px 1px black;
  border-radius: 10px;
  border: 1px solid #823939;
}

.price-cut {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(0deg, #a21414, #e01c1c);
  padding: 2px 5px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  text-shadow: 1px 1px black;
  border: 1px solid #823939;
  z-index: 1;
  font-size: 26px;
}
</style>
