<template>
  <div class="page">
    <div style="max-width:600px;margin:0 auto">
      <div class="order-notice" v-if="unpaid_order_uid && unpaid_order_uid != order_uid">Twoje ostatnie zamówienie nie zostało jeszcze opłacone: <a :href="'/orders/summary?id=' + unpaid_order_uid">podsumowanie zamówienia</a></div>
      <template v-if="!confirmed">
        <h2 v-if="!orderingMode">Koszyk</h2>
        <h2 v-if="orderingMode"><a v-on:click="toggleOrderingMode">Koszyk</a> &raquo; Wysyłka</h2>
      </template>
      <h2 v-if="confirmed">Płatność</h2>
      <div class="order-success" v-if="paid">Dziękujemy za złożenie zamówienia, płatność przebiegła pomyślnie! Wyślemy je jak najszybciej :)</div>

      <template v-if="!orderingMode">
<!--         <div class="cards-price">
          <span>Cena netto:</span>
          <span>{{ netto.toFixed(2) }} zł</span>
        </div>
 --><!--         <div class="cards-price">
          <span><a href='https://kapitalni.org/pl/artykuly/co-to-jest-vat-i-jak-dziala,73,499' target='_blank'>Podatek VAT</a>:</span>
          <span>{{ vat.toFixed(2) }} zł</span>
        </div>
 -->
<!--         <div class="cards-price flex flex-column">
          <div class='flex'><span>Kupon zniżkowy:</span></div>
          <form v-on:submit.prevent v-if="!promoCodeDiscountPercentage" class='flex flex-row mb2 mt2'>
            <div class='flex w-50 mr2'><input type="text" id="promo_code" v-model="promoCode" /></div>
            <div class='flex w-50 ml2'><button class="button submit-button my-auto" v-on:click="applyPromoCode">Aktywuj kupon</button></div>
          </form>
          <div v-if="promoCodeDiscountPercentage" class='f4'><b>{{ promoCode }}</b> ({{ promoCodeDiscountPercentage }}% zniżki)</div>
          <div v-if="invalidPromoCode" class='error mb2'>Nieprawidłowy kod</div>
        </div>
 -->
       <div v-if="hasDiscount" class="cards-price bolder" style='color:green'>
          <span>Oszczędzasz:</span>
          <span>{{ parseFloat(discount).toFixed(2) }} zł</span>
        </div>
        <div class="cards-price bolder">
          <span>Cena:</span>
          <span>{{ price.toFixed(2) }} zł</span>
        </div>
      </template>

      <button v-if="!orderingMode && items.length" class="button ordering-mode-button submit-button" v-on:click="toggleOrderingMode">Wypełnij dane do wysyłki</button>
      <button v-if="clearingVisible" class="button cancel" v-on:click="clearOrder">Wyczyść koszyk</button>

      <form v-if="orderingMode" v-on:submit="submit" action="/api/orders/confirm" method="post">
        <div v-if="confirmed" class="field">
          <label>Status płatności</label>
          <input type="text" :value="paid ? 'Opłacone' : 'Nieopłacone'" disabled />
        </div>
        <div v-if="confirmed && !paid" class="actions" style="padding: 10px 0">
          <button class="button submit-button">Ponów płatność</button>
          <button class="button" v-on:click="cancelOrder">Anuluj zamówienie</button>
          <div style="padding: 10px 0">W razie problemów z płatnością prosimy o kontakt na nasz adres e-mail: collectemall.store@gmail.com lub przez <a href='https://www.facebook.com/profile.php?id=100063937005132' target='_blank'>Facebook</a>.</div>
        </div>
        <div class="field">
          <label for="email">E-mail</label>
          <input id="email" name="EMAIL" type="text" v-model="email" required :disabled="confirmed" />
        </div>
        <div class="field">
          <label for="first_name">Imię</label>
          <input id="first_name" name="first_name" type="text" v-model="firstName" :disabled="confirmed" required />
        </div>
        <div class="field">
          <label for="last_name">Nazwisko</label>
          <input id="last_name" name="last_name" type="text" v-model="lastName" :disabled="confirmed" required />
        </div>
        <template v-if="!onlyCodes">
          <div class="field">
            <label for="address">Adres</label>
            <input id="address" name="address" type="text" v-model="address" :disabled="confirmed" required />
          </div>
          <div class="field">
            <label for="postal_code">Kod pocztowy</label>
            <input id="postal_code" name="postal_code" type="text" v-model="postalCode" :disabled="confirmed" required />
          </div>
          <div class="field">
            <label for="city">Miasto</label>
            <input id="city" name="city" type="text" v-model="city" :disabled="confirmed" required />
          </div>
          <div class="field">
            <label for="phone">Numer telefonu</label>
            <input id="phone" name="phone" type="text" v-model="phone" :disabled="confirmed" required />
          </div>
        </template>
        <div class="field my-8" style='text-align: center'>
          <table class='shipment'>
            <thead>
              <tr>
                <th>Wysyłka</th>
                <th>Koszt</th>
                <th>Czas transportu</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="!onlyCodes">
                <tr>
                  <td style='text-align:left'>
                    <label class="radio">
                      <input type="radio" name="shipment_method" value="inpost" v-model="shipmentMethod" :disabled="confirmed" required />
                      <div><img src='/images/shipment/inpost.jpg' /></div>
                      Paczkomat InPost
                    </label>
                  <td v-if="!freeShipping" style='text-align:right'>{{ shippingMethods.inpost.cost.toFixed(2) }} zł</td>
                  <td v-if="freeShipping" style='text-align:right'><s class="free-shipping-price">{{ shippingMethods.inpost.cost.toFixed(2) }} zł</s> 0 zł</td>
                  <td>1-2 dni</td>
                </tr>
                <tr>
                  <td style='text-align:left'>
                    <label class="radio">
                      <input type="radio" name="shipment_method" value="courier" v-model="shipmentMethod" :disabled="confirmed" required />
                      <div><img src='/images/shipment/inpost.jpg' /></div>
                      Kurier InPost
                    </label>
                  <td v-if="!freeShipping" style='text-align:right'>{{ shippingMethods.courier.cost.toFixed(2) }} zł</td>
                  <td v-if="freeShipping" style='text-align:right'><s class="free-shipping-price">{{ shippingMethods.courier.cost.toFixed(2) }} zł</s> 0 zł</td>
                  <td>2-3 dni</td>
                </tr>
              <tr>
                  <td style='text-align:left'>
                    <label class="radio">
                      <input type="radio" name="shipment_method" value="ruch" v-model="shipmentMethod" :disabled="confirmed" required />
                      <div><img src='/images/shipment/orlen.png' /></div>
                      Orlen Paczka
                    </label>
                  <td v-if="!freeShipping" style='text-align:right'>{{ shippingMethods.ruch.cost.toFixed(2) }} zł</td>
                  <td v-if="freeShipping" style='text-align:right'><s class="free-shipping-price">{{ shippingMethods.ruch.cost.toFixed(2) }} zł</s> 0 zł</td>
                  <td>3-7 dni</td>
                </tr>
              </template>
              <template v-if="onlyCodes">
                <tr>
                  <td style='text-align:left'>
                    <label class="radio">
                      <input type="radio" name="shipment_method" value="email" v-model="shipmentMethod" :disabled="confirmed" required />
                      <div><img src='/images/shipment/email.png' /></div>
                      E-mail
                    </label>
                  <td style='text-align:right'>0.00 zł</td>
                  <td>Natychmiast</td>
                </tr>
              </template>
<!--               <tr>
                <td style='text-align:left'>
                  <label class="radio">
                    <input type="radio" name="shipment_method" value="post" v-model="shipmentMethod" required />
                    <div><img src='/images/shipment/post.png' /></div>
                    Poczta Polska, polecony priorytet
                  </label>
                <td style='text-align:right'>10,50 zł</td>
                <td>1-2 dni</td>
              </tr>
 -->     
            </tbody>
          </table>
          <!-- <label style="padding-top:15px">Wysyłka</label> -->
          
        </div>
        <div v-if="!freeShipping" class="free-shipping-treshold">
          <span>Brakuje {{ freeShippingTreshold.toFixed(2) }} zł do darmowej dostawy</span>
        </div>
        <input type="hidden" v-model="pickupPointAddress" id="pickup_point_address" name="pickup_point_address" :disabled="confirmed" />
        <div class="field" v-if="shipmentMethod == 'inpost'">
          <label for="inpost_point">Numer paczkomatu</label>
          <!-- <input id="inpost_point" name="inpost_point" type="text" v-model="inpostPoint" required placeholder='np. LOD22A' /> -->
          <input id="inpost_point" name="inpost_point" type="text" v-model="inpostPoint" :disabled="confirmed" required />
          <button v-if="!confirmed" class="location" v-on:click="showMap"><img src="/images/location.svg" /> Wybierz z mapy</button>
          <!-- <a href="https://inpost.pl/znajdz-paczkomat" class='search' target="_blank">Wyszukiwarka</a> -->
        </div>
        <div class="field" v-if="shipmentMethod == 'ruch'">
          <label for="ruch_point">Punkt odbioru</label>
          <input id="ruch_point" name="ruch_point" type="text" v-model="ruchPoint" :disabled="confirmed" required />
          <!-- <a style='margin: auto 0 auto 8px' class='search' href="https://www.orlenpaczka.pl/pokaz-punkty/" target="_blank">Wyszukiwarka</a> -->
          <button v-if="!confirmed" class="location" v-on:click="showMap"><img src="/images/location.svg" /> Wybierz z mapy</button>
        </div>
        <div class="field" style="width: 560px">
          <label style='width:99%;text-align: center' for="comment">Uwagi do zamówienia / Dane do faktury VAT</label>
          <textarea id="comment" name="comment" type="text" v-model="comment" :disabled="confirmed" style='width: 99%;height:75px' />
        </div>
        <div class="mt-16">
<!--           <div class="cards-price">
            <span>Cena netto:</span>
            <span>{{ netto.toFixed(2) }} zł</span>
          </div>
          <div class="cards-price">
            <span><a href='https://kapitalni.org/pl/artykuly/co-to-jest-vat-i-jak-dziala,73,499' target='_blank'>Podatek VAT</a>:</span>
            <span>{{ vat.toFixed(2) }} zł</span>
          </div>
 -->
          <div v-if="promoCodeDiscountPercentage" class="cards-price flex flex-column">
            <div class='flex'><span>Kupon zniżkowy:</span></div>
            <div class='f4'><b>{{ promoCode }}</b> ({{ promoCodeDiscountPercentage }}% zniżki)</div>
          </div>
          <div v-if="hasDiscount" class="cards-price bolder" style='color:green'>
            <span>Oszczędzasz:</span>
            <span>{{ parseFloat(discount).toFixed(2) }} zł</span>
          </div>
          <div class="cards-price">
            <span>Cena:</span>
            <span>{{ price.toFixed(2) }} zł</span>
          </div>
          <div class="cards-price">
            <span>Dostawa:</span>
            <span v-if="!freeShipping || originalShippingCost == 0">{{ shipmentPrice.toFixed(2) }} zł</span>
            <span v-if="freeShipping && originalShippingCost > 0"><s class="free-shipping-price">{{ originalShippingCost.toFixed(2) }} zł</s> 0.00 zł</span>
          </div>
          <div class="cards-price bolder">
            <span>Cena całkowita:</span>
            <span>{{ totalPrice.toFixed(2) }} zł</span>
          </div>
          <template v-if="user_balance > 0">
            <div class="cards-price" style='color:green'>
              <span>Twoje środki:</span>
              <span>{{ parseFloat(user_balance).toFixed(2) }} zł</span>
            </div>
            <div class="cards-price bolder" style='color:green'>
              <span>Do zapłaty:</span>
              <span>{{ totalPriceMinusBalance.toFixed(2) }} zł</span>
            </div>
          </template>
        </div>
        <div v-if="!confirmed"><label for='terms'><input type='checkbox' id='terms' required /> Akceptuję <a href='/pages/terms_of_service' target='_blank'>Regulamin</a> i <a href='/pages/privacy_policy' target='_blank'>Politykę prywatności</a> sklepu collectemall.app</label></div>
        <div class="actions">
          <button v-if="!confirmed" class="button submit-button">Złóż zamówienie</button>
          <!-- <button v-if="!confirmed" class="button ordering-mode-button cancel" v-on:click="toggleOrderingMode">Edytuj zamówienie</button> -->
        </div>
      </form>
      <form ref='payment' style='display:none' action="https://platnosc.hotpay.pl/" method="post">
        <input required name="SEKRET" value="QkY1aFJiUkxoVkFkV2FKR3pIc2JmZDlUVjJxSGxaZTY3WHhsVjBnR2t0TT0," type="hidden">
        <input required name="KWOTA" :value="totalPriceMinusBalance.toFixed(2)" type="hidden">
        <input required name="ADRES_WWW" :value="orderUrl" type="hidden">
        <input required name="ID_ZAMOWIENIA" :value="id" type="hidden">
        <input required name="NAZWA_USLUGI" :value="itemNames" type="hidden">
        <input name="DANE_OSOBOWE" :value="fullName" type="hidden">
        <input name="EMAIL" :value="email" type="hidden">
      </form>
    </div>
    
     <section class='mt-16'>
      <cart-item
        v-for="item in items"
        :item="item"
        :key="item.id"
        :orderingMode="orderingMode"
      />
    </section>
  </div>
</template>

<script>
var perfEntries = performance.getEntriesByType("navigation");
if (perfEntries[0].type === "back_forward") {
  location.reload();
}

import CartItem from './cart_item.vue';

export default {
  props: ['initial_price', 'user_balance', 'user_email', 'unpaid_order_uid', 'order_uid'],
  data() {
    return {
      id: null,
      items: [],
      cartUid: null,
      discount: null,
      orderingMode: false,
      price: 0,
      promoCode: null,
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      city: '',
      shipmentMethod: null,
      phone: '',
      ruchPoint: '',
      inpostPoint: '',
      pickupPointAddress: '',
      comment: '',
      invalidPromoCode: false,
      promoCodeDiscountPercentage: null,
      confirmed: false,
      paid: false,
      onlyCodes: false,
      freeShipping: false,
      shippingMethods: { inpost: {}, ruch: {}, courier: {}, email: {} },
    }
  },
  created() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.price = parseFloat(this.initial_price)
    this.unpaid_order_uid = this.unpaid_order_uid
    this.email = this.user_email
    this.$root.$on('updateCart', (cart) => {
      this.price = parseFloat(cart.price);
    })
    var order_url = '/api/orders/' + this.order_uid
    this.$http.get(order_url).then(function(response) {
      this.firstName = response.body.first_name
      this.lastName = response.body.last_name
      this.email = response.body.email
      this.address = response.body.address
      this.postalCode = response.body.postal_code
      this.city = response.body.city
      this.phone = response.body.phone
      this.ruchPoint = response.body.ruch_point
      this.inpostPoint = response.body.inpost_point
      this.comment = response.body.comment

      this.items = response.body.items
      this.id = response.body.id
      this.cartUid = response.body.cart_uid
      this.discount = response.body.discount
      this.promoCodeDiscountPercentage = response.body.promo_code_discount_percentage
      this.promoCode = response.body.promo_code
      this.confirmed = response.body.confirmed
      this.paid = response.body.paid
      this.orderingMode = this.confirmed
      this.onlyCodes = response.body.only_codes
      this.freeShipping = response.body.free_shipping
      this.shippingMethods = response.body.shipping_methods
      if(response.body.shipment_method) {
        this.shipmentMethod = response.body.shipment_method
      } else {
        this.shipmentMethod = this.onlyCodes ? 'email' : 'inpost'
      }
      const checkoutItems = this.items.map(function(i) {
        return { id: i.id, name: i.full_name, variant: i.version, category: i.collection_set_name, quantity: i.quantity, price: i.total_price }
      })
      gtag('event', 'begin_checkout', { items: checkoutItems, value: response.body.price, currency: 'PLN' })
    });
  },
  computed: {
    itemNames: function() {
      return this.items.map(i => i.name).join(', ')
    },
    shipmentPrice: function() {
      if(this.freeShipping || this.shipmentMethod == 'email' || !this.orderingMode) {
        return 0
      } else {
        return this.originalShippingCost
      }
    },
    totalPrice: function() {
      return this.price + this.shipmentPrice;
    },
    totalPriceMinusBalance: function() {
      let p = this.totalPrice - this.user_balance
      if(p < 0) { p = 0 }
      return p
    },
    originalShippingCost: function() {
      return this.shippingMethods[this.shipmentMethod].cost
    },
    freeShippingTreshold: function() {
      return 150 - this.price
    },
    netto: function() {
      return Math.round(this.price * 77) / 100
    },
    vat: function() {
      return this.price - this.netto
    },
    fullName: function() {
      return this.firstName + ' ' + this.lastName
    },
    clearingVisible: function() {
      return this.items.length > 0 && !this.orderingMode;
    },
    orderUrl: function() {
      return 'https://collectemall.app/orders/summary?id=' + this.order_uid
    },
    hasDiscount: function() {
      return this.discount && this.discount != '0.0'
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault()
      var order_url = e.target.action
      if(this.order_uid) {
        order_url = order_url + '?order_uid=' + this.order_uid
      }
      this.$http.post(order_url, {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        address: this.address,
        postal_code: this.postalCode,
        city: this.city,
        shipment_method: this.shipmentMethod,
        phone: this.phone,
        ruch_point: this.ruchPoint,
        inpost_point: this.inpostPoint,
        pickup_point_address: this.pickupPointAddress,
        comment: this.comment
      }).then(function(response) {
        switch(response.body.status) {
          case 'ok':
            if(response.body.paid) {
              window.location = this.orderUrl
            } else {
              this.$refs.payment.submit()
            }
            break
          case 'unavailable':
            location.reload()
            break
          case 'error':
            alert(response.body.error)
        }
      })
    },
    applyPromoCode: function() {
      this.$http.get('/api/orders/apply_promo_code?code='+this.promoCode).then(function(response) {
        this.price = response.body.price
        this.discount = response.body.discount
        this.promoCodeDiscountPercentage = response.body.discount_percentage
        this.invalidPromoCode = false
      }, function() {
        this.invalidPromoCode = true
      })
    },
    toggleOrderingMode: function() {
      if(!this.orderingMode) {
        var order_url = '/api/orders/' + this.order_uid
        this.$http.get(order_url).then(function(response) {
          this.onlyCodes = response.body.only_codes
          if(this.onlyCodes) {
            this.shipmentMethod = 'email'
          }
          this.freeShipping = response.body.free_shipping
        })
      }

      if(!this.confirmed) {
        this.orderingMode = !this.orderingMode;
      }
    },
    cancelOrder: function(e) {
      e.preventDefault();
      if(confirm('Czy na pewno chcesz anulować zamówienie?')) {
        var that = this;
        this.$http.delete('/api/orders?order_uid=' + that.order_uid).then(function(response) {
          window.location = '/';
        });
      }
    },
    clearOrder: function() {
      if(confirm('Czy na pewno chcesz wyczyścić koszyk?')) {
        var that = this;
        this.$http.delete('/api/orders').then(function(response) {
          that.items = response.body.items;
          that.discount = 0;
          that.$root.$emit('updateCart', response.body);
        });
      }
    },
    showMap: function(e) {
      e.preventDefault();
      const that = this;
      new window.Furgonetka.Map({
        courierServices: [this.shipmentMethod],
        city: this.city,
        street: this.address,
        callback: function(point) {
          if(that.shipmentMethod == 'inpost') {
            that.inpostPoint = point.code;
          } else if(that.shipmentMethod == 'ruch') {
            that.ruchPoint = point.code;
          }
          that.pickupPointAddress = point.name;
        }
      }).show();
    }
  },
  components: { CartItem },
}
</script>

<style scoped>
.error {
  color: red;
}
input[type=text], input[type=password], input[type=email], input[type=number], input[type=url], input[type=tel], input[type=date], input[type=time], textarea {
  border: 1px solid #c9d0d6;
}
form ul, form ol, form li, form fieldset, form legend, form input, form textarea, form select, form p {
  margin: 0;
}
form .buttons, form .actions {
  margin-top: 15px;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #878c90;
}
a:hover {
  color: #5E6469;
}
.field {
  overflow: hidden;
}
.field label:not(.radio) {
  width: 130px;
  display: block;
  float: left;
  text-align: right;
  padding: 8px;
}
.field label.radio {
  display: block;
}

.field input[type="text"] {
  width: 230px;
  padding: 8px;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
section {
  clear: both;
  width: 100%;
  color: white;
  margin-bottom: 50px;
}
nav label {
  float: left;
  padding: 15px 25px;
  border: 1px solid #ddd;
  border-bottom: 0;
  background: #696969;
  color: white;
}
nav li.active label {
  background: #151515;
  position: relative;
}
nav li.active label:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #151515;
  left: 0;
  bottom: -1px;
}
.cards-price {
  margin-bottom: 20px;
  border: dashed #cacaca;
  border-width: 1px 0;
  width: 400px;
}
.cards-price span {
  font-size: 20px;
  display: block;
}
.cards-price span:first-child {
  width: 200px;
  float: left;
}
.free-shipping-treshold {
  overflow: auto;
  margin-bottom: 20px;
  margin-top: 10px;
  color:green
}
.free-shipping-treshold span {
  font-size: 14px;
  width: auto;
}
.free-shipping-price {
  font-size: 12px;
  line-height: 16px;
  text-decoration: line-through;
  color: #767676;
  -webkit-text-decoration-color: black;
  text-decoration-color: black;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
}

.submit-button {
  background-image: linear-gradient(180deg, #a9bb16, #717d0a);
}
.submit-button:hover {
  background-image: linear-gradient(180deg, #b6ca15, #79860c);
}
.submit-button:active {
  background-image: linear-gradient(180deg, #9eaf11, #576108);
}
table.shipment, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 3px 6px;
}
table.shipment th {
  background-color: #cccccc;
}
table.shipment input[type='radio'] {
  vertical-align: middle;
}

table.shipment label.radio {
  display: flex;
  cursor: pointer;
}
table.shipment label.radio input {
  margin: auto 0;
}
table.shipment label.radio > div {
  width: 41px;
  display: flex;
  margin: auto 5px;
}
table.shipment label.radio > div > img {
  height: 20px;
  margin: 0 auto;
}
.search {
  color: #346790;
}
.search::before {
  content: url('/images/shipment/search.png');
  position: relative;
  top: 3px;
  margin-right: 2px;
}
.search:hover {
  color: black;
}
.location {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  background: white;
  height: 33px;
  padding: 0 13px 0 8px;
  color: #046db4;
  border: 1px solid #c3c5c7;
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  transform: translateY(-1px);
}
.location:hover {
  color: #0091e6;
  border: 1px solid #05acff;
  box-shadow: 0.5px 0.9px 4px 0 rgb(34 34 34 / 15%), inset 0 0 0 1px #05acff;
  background: #fff;
  outline: 0;
}
.order-notice {
  font-size: 21px;
  margin: 20px 0;
}
.order-success {
  font-size: 15px;
  margin: 20px 0;
}
.button.cancel {
  background: #707070;
}
</style>
