<template>
  <div :class="'page collection-set-' + collection_set_id">
    <input type="radio" id="version_all" value="all" v-model="selectedVersion" />
    <input type="radio" id="version_regular" value="regular" v-model="selectedVersion" />
    <input type="radio" id="version_reverse" value="reverse" v-model="selectedVersion" />
    <!-- <h2 v-if='carousel' class='center'>Ostatnio dodane</h2> -->

    <nav v-if="loaded">
      <ul v-if="collection_set_id && (paginationEnabled || !carouselEnabled)">
        <li class="title active">
          <a :href="'/collection_sets/' + collection_set_id">
            <label :style="carouselEnabled ? 'left:20px;margin-left: 22px;' : ''">
              <div>
                <img class="set-symbol" :src="symbol_url" :alt="collection_set_code" :title="collection_set_code" />
              </div>
              <div class="ml-8">
                <img class="set-logo" :src="logo_url" :alt="collection_set_name" :title="collection_set_name" />
              </div>
            </label>
          </a>
        </li>
      </ul>
      <ul v-if="hasVersions && !carouselEnabled">
        <li :class="{ active: selectedVersion == 'all'}"><label for="version_all">Wszystkie ({{ versionSize('all') }})</label></li>
        <li :class="{ active: selectedVersion == 'regular'}"><label for="version_regular">Zwyczajne ({{ versionSize('regular') }})</label></li>
        <li :class="{ active: selectedVersion == 'reverse'}"><label for="version_reverse">Reversy ({{ versionSize('reverse') }})</label></li>
      </ul>
    </nav>

    <img v-if="!loaded" :style="carouselEnabled ? 'width: auto;height: 471px' : ''" class="loader" src="/images/pokeball.gif" alt="Trwa ładowanie, proszę czekać..." title="Trwa ładowanie, proszę czekać..."/>
    <section v-if="carouselEnabled" class='binder'>
      <carousel
        ref='carousel'
        :per-page-custom='[[0,3],[1213,4],[1440,5],[1667,6],[1894,7],[2121,8],[2348,9]]'
        :navigation-enabled='true'
        :navigation-click-target-size='6'
        :pagination-enabled='paginationEnabled'
        v-on:page-change="pageChanged"
        :loop='true'
        :autoplay-timeout='4000'
        :autoplay='true'
      >
        <slide v-for="(collectable, index) in shownCollectables">
          <collectable
            ref='collectables'
            :collectable='collectable'
            :index='index'
            :key='collectable.id'
            :user_signed_in='user_signed_in'
            :collection_set_permalink='collection_set_permalink'
            :carousel='true'
          />
        </slide>
      </carousel>
    </section>
    <section v-if="!carouselEnabled">
      <collectable v-for="collectable in shownCollectables"
        :collectable="collectable"
        :key="collectable.id"
        :user_signed_in="user_signed_in"
        :collection_set_permalink="collection_set_permalink"
        :batch='batch'
      />
    </section>
  </div>
</template>
        <!-- :per-page-custom='[[532,1],[759,2],[986,3],[1213,4],[1440,5],[1667,6],[1894,7],[2121,8],[2348,9]]' -->

<script>
import Collectable from './collectable.vue';
import { Carousel, Slide } from 'vue-carousel';

export default {
  props: [
    'collection_set_id',
    'query',
    'sale',
    'user_signed_in',
    'collection_set_permalink',
    'carouselEnabled',
    'collection_set_name',
    'collection_set_code',
    'batch'
  ],
  data() {
    return {
      logo_url: '',
      symbol_url: '',
      collectables: [],
      permalink: '',
      selectedVersion: 'all',
      loaded: false
    }
  },
  created() {
    if(this.collection_set_id) {
      this.$http.get('/api/collection_sets/' + this.collection_set_id).then(function(response) {
        this.logo_url = response.body.logo_url
        this.symbol_url = response.body.symbol_url
        this.collectables = response.body.collectables
        this.permalink = response.body.permalink
        this.loaded = true
      });
    } else if(this.query) {
      this.$http.get('/api/collectable_versions?query=' + this.query).then(function(response) {
        this.collectables = response.body.collectables
        this.loaded = true
      });
    } else if(this.sale) {
      this.$http.get('/api/collectable_versions?sale=1').then(function(response) {
        this.collectables = response.body.collectables
        this.loaded = true
      });
    }
  },
  updated() {
    setTimeout(this.animateDot, 100)
    this.loadImagesForPage(0)
    this.loadImagesForPage(1)
  },
  components: { Collectable, Carousel, Slide },
  computed: {
    shownCollectables: function() {
      return this.versionCollectables(this.selectedVersion);
    },
    hasVersions: function() {
      return !(this.permalink == 'boxes' || this.permalink == 'coins' || this.permalink == 'accessories' || this.permalink == 'albums' || this.permalink == 'ptcgo')
    },
    paginationEnabled: function() {
      return window.innerWidth >= 1213
    }
  },
  methods: {
    versionCollectables: function(version) {
      // if(version == 'all') {
      //   return this.collectables;
      // }
      var user_signed_in = this.user_signed_in
      return this.collectables.filter(function(collectable) {
        return (version == 'all' || version == collectable.version) && (user_signed_in || collectable.quantity_for_trade > 0)
      })
    },
    versionSize: function(version) {
      return this.versionCollectables(version).length
    },
    pageChanged: function(page) {
      setTimeout(this.animateDot, 100)
      this.loadImagesForPage(page)
      this.loadImagesForPage(page+1)
      this.loadImagesForPage(page-1)
    },
    loadImagesForPage(page) {
      if (this.$refs.carousel) {
        const perPage = this.$refs.carousel.currentPerPage
        const firstIndex = page * perPage
        const collectablesLength = this.$refs.collectables.length
        if(firstIndex >= this.$refs.collectables.length || firstIndex < 0) { return }
        for(var i = firstIndex, x = 0; i < firstIndex + perPage; i++) {
          if(i < collectablesLength) {
            x = i
          } else {
            // If last page has less elements than perPage, fill it
            x = firstIndex - (i - collectablesLength) - 1
          }
          this.$refs.collectables[x].loadImage = true
        }
      }
    },
    animateDot: function() {
      const dot = document.getElementsByClassName('VueCarousel-dot--active')[0]
      if(dot) {
        dot.className = 'VueCarousel-dot VueCarousel-dot--active-final'
      }
    }
  }
}
</script>

<style scoped>
h2 {
  margin: 5px 0;
  font-size: 2em;
}
.loader {
  width: 30%;
  margin: 0 auto;
  display: block;
}
input[type="radio"] {
  display: none;
}
li {
  display: block;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav label {
  float: left;
  padding: 10px 25px;
  border: 1px solid #ddd;
  margin-top: 8px;
  border-bottom: 0;
  background: #696969;
  color: white;
}
nav label:hover {
  background: #807d7d;
}
nav label:active {
  background: #151515;
}
nav li:first-child label {
  border-radius: 10px 0 0 0;
}
nav li:last-child label {
  border-radius: 0 10px 0 0;
}
nav li:not(:last-child) label {
  border-right-width: 0;
}

section {
  clear: both;
  color: white;
  margin: 0 auto 25px;
}
nav li.active label {
  background: #151515;
  position: relative;
}
nav li.active label:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #151515;
  left: 0;
  bottom: -1px;
}

/* Set logo */
nav ul li.title label > div {
  height: 48px;
  line-height: 48px;
  float: left;
}
nav ul li.title label > div img {
  vertical-align: middle;
}
nav ul li.title label {
  margin-top: 0;
  padding: 0 8px;
  margin-right: 32px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
ul li.title label:after {
  bottom: -4px;
}

img.set-symbol {
  height: 35px;
}
img.set-logo {
  max-height: 35px;
}
</style>