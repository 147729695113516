<template>
  <div class="cart">
    <a :href="cart_path">
      <img class="cart-icon" src="/images/shopping_trolley.svg" />
      <span class="cart-quantity">{{ cart_size }}</span>
    </a>
    <div class="cart-alert" style="display: none">
      <template v-if="added_collectable_name">Dodano kartę {{ added_collectable_name }}</template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart_size: 0,
      added_collectable_name: null
    }
  },
  props: ['cart_path', 'current_cart_size'],
  created() {
    this.cart_size = this.current_cart_size;
    this.$http.get('/api/orders/current_size').then(function(response) {
      this.cart_size = response.body.current_cart_size;
    });
  },
  mounted() {
    this.$root.$on('updateCart', (cart, collectable_name) => {
      this.cart_size = cart.quantity;
      if(!collectable_name) { return }
      this.added_collectable_name = collectable_name;
      $('.cart-alert').remove()
      var message = $("<div class='cart-alert' style='display:none'><a href='/orders/current'><p>Produkt "+collectable_name+" został dodany do koszyka</p><p>Stan zamówienia: "+cart.price.toFixed(2)+"zł</p></a></div>")
      $('body').prepend(message)
      message.fadeIn(500, function() {
        setTimeout(function() { message.fadeOut(500); }, 5000)
      });
    });
  }
}
</script>

<style scoped>
.cart-icon {
  height: 40px;
}
.cart-quantity {
  top: -30px;
  left: -65px;
  position: relative;
  background-color: #dc3545; 
  color: white;
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
  display: inline-block;
  padding: .25em .4em;
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.cart {
  font-size: 30px;
  float: right;
  margin-right: 50px;
  margin-top: 12px;
}
.cart a {
  text-decoration: none;
  color: #4e4e4e;
  cursor: pointer;
}
.cart a:hover {
  color: black;
}
</style>